import React from "react";

import "../styles/centered-box.less";
import "../styles/offres.less";

import { Layout, Row, Col, Card, Button, Tag } from "antd";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";
import ShowOffer from "../components/ShowOffer.js";
import { navigate } from '../utils'

const { Content } = Layout;

export default function OurOffers() {
  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main" style={{ minWidth: "1060px" }}>
            <h1>Roulez serein !</h1>
            <h2>Deux packs, zero surprise</h2>
            <Row gutter={16}>
              <Col className="offres" xs={24} lg={12}>
                <Card
                  title={
                    <div>
                      <h2>Droit de rouler</h2>
                      <p className="price">4,99€&nbsp;/&nbsp;mois</p>
                    </div>
                  }
                >
                  <Button
                    onClick={() => navigate("/")}
                    className="btn-selectionner"
                    type="primary"
                    size="large"
                  >
                    Je m'assure
                  </Button>
                  <ShowOffer offer="droit_de_rouler" />
                </Card>
              </Col>

              {/*<Col className="offres prefere" xs={24} lg={8}>
                <Card
                  title={
                    <div>
                      <h2>Free Rider</h2>
                      <p className="price">13,99€&nbsp;/&nbsp;mois</p>
                    </div>
                  }
                >
                  <Button
                    onClick={() => navigate("/")}
                    className="btn-selectionner"
                    type="primary"
                    size="large"
                  >
                    Je m'assure
                  </Button>
                  <br />
                  <Tag color="grey">Pack préféré par nos membres</Tag>
                  <ShowOffer offer="free_rider" />
                </Card>
              </Col>*/}

              <Col className="offres" xs={24} lg={12}>
                <Card
                  title={
                    <div>
                      <h2>Esprit tranquille</h2>
                      <p className="price">
                        <span>à partir de </span>16,00€&nbsp;/&nbsp;mois
                      </p>
                    </div>
                  }
                >
                  <Button
                    onClick={() => navigate("/")}
                    className="btn-selectionner"
                    type="primary"
                    size="large"
                  >
                    Je m'assure
                  </Button>
                  <ShowOffer offer="esprit_tranquille" />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
